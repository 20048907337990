<div class="flex-vertical-container control-container">
  <md-card style="margin-bottom: 10px" class="flex-1">
    <div class="flex-vertical-container" style="justify-content: center">
      <h2 class="text-center">Take the control !</h2>
    </div>
  </md-card>

  <md-card style="margin-bottom: 10px">
    <h2 class="text-center">Scan the QRCode</h2>
    <div class="text-center">
      <img [src]="channelQRCode"/>
    </div>
  </md-card>

  <md-card style="word-break: break-all">
    <h2 class="text-center">Or GO TO</h2>
    <p style="font-size: 26px" class="text-center">{{boothURL}}</p>
  </md-card>

</div>
