<swiper [config]="swiperConfig" #swiper>
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let product of products">
      <div class="text-center">
        <img [src]="productService.getProductThumbnailSrc(product)"/>
      </div>
      <p class="text-center" style="font-size: 22px">{{product.brand.name}}</p>
      <p class="text-center" style="font-size: 22px">{{product.model}}</p>
      <p class="text-center" style="font-size: 22px" *ngIf="product.sellPrice">{{product.sellPrice}} €</p>
    </div>
  </div>
  <!-- Add Arrows -->
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
</swiper>
