<div class="text-center instructions">
  <h1>Personal data</h1>
  <h3>
    By clicking "I accept", you allow your optician to keep the video you are about to record. Otherwise click "I refuse".
  </h3>
  <button md-raised-button (click)="accept()" color="primary">
    I accept
  </button>
  <button md-raised-button (click)="dismiss()" color="warn">
    I refuse
  </button>
</div>
