<div class="flex-vertical-container">
  <md-card class="flex-1">
    <md-nav-list style="min-height: 538px" class="product-list">
      <md-list-item *ngFor="let product of products" (click)="selectProduct(product)" class="product">
        <img mdListAvatar class="product-avatar-img" [src]="productService.getProduct360ImageUrl(product, 1)">
        <h3 mdLine>{{product.brand.name}}</h3>
        <p mdLine>
          <span>{{product.model}}</span>
        </p>
      </md-list-item>
    </md-nav-list>
  </md-card>
  <md-card class="flex-0">
    <md-grid-list cols="3" rowHeight="36px">
      <md-grid-tile>
        <button type="button" md-raised-button color="primary" (click)="previousPage()" [disabled]="pageIndex === 0">
          Previous
        </button>
      </md-grid-tile>
      <md-grid-tile *ngIf="totalNumberOfProductsMatchingRequest > 0">
        {{pageIndex * pageSize + 1}} - {{pageSize * pageIndex + products.length}} / {{totalNumberOfProductsMatchingRequest}}
      </md-grid-tile>
      <md-grid-tile *ngIf="totalNumberOfProductsMatchingRequest === 0">
        No result
      </md-grid-tile>
      <md-grid-tile>
        <button type="button" md-raised-button color="primary" (click)="nextPage()"
                [disabled]="pageSize * pageIndex + products.length === totalNumberOfProductsMatchingRequest">
          Next
        </button>
      </md-grid-tile>
    </md-grid-list>
  </md-card>
</div>
