<div>
  <div class="flex-horizontal-container" style="margin-top: 10px;">
    <app-reset></app-reset>
  </div>

  <div class="flex-horizontal-container" style="margin-top: 20px;">
    <div style="width: 500px">
      <app-interpupillary-distance></app-interpupillary-distance>
    </div>

    <div>
      <app-views></app-views>
    </div>

    <div style="width:500px;">
      <app-y-translation></app-y-translation>
      <div style="margin-top: 100px;">
        <app-x-rotation></app-x-rotation>
      </div>
    </div>
  </div>

  <div class="flex-horizontal-container" style="margin-top: 20px;">

  </div>
  <app-color-range-picker-horizontal-swatch></app-color-range-picker-horizontal-swatch>
</div>
