<md-card>
  <md-card-subtitle class="text-center lense-treatments-category-title">Solar Lenses</md-card-subtitle>
  <div class="text-center">
    <div class="solar-lense-container">
      <md-radio-group name="solarLenseRadio" [(ngModel)]="solarLense">
        <md-radio-button *ngFor="let aSolarLense of solarLenses" [value]="aSolarLense">
          <img [src]="'assets/' + aSolarLense.icon + '.png'"/>
        </md-radio-button>
      </md-radio-group>
    </div>
  </div>
</md-card>

<md-card>
  <md-card-subtitle class="text-center lense-treatments-category-title">Anti Reflective Coating</md-card-subtitle>
  <div class="text-center">
    <div class="anti-reflection-container">
      <md-radio-group name="antiReflectionCoatingRadio" [(ngModel)]="antiReflectionCoating">
        <md-radio-button *ngFor="let aAntiReflectionCoating of antiReflectionCoatings" [value]="aAntiReflectionCoating">
          <img [src]="'assets/' + aAntiReflectionCoating.icon + '.png'"/>
        </md-radio-button>
      </md-radio-group>
    </div>
  </div>
</md-card>
