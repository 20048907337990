<div class="text-center color-filter-container" style="height: 64px;">
  <md-slide-toggle [(ngModel)]="enabled" class="color-filter-toggle"><span *ngIf="!enabled">Color filter</span></md-slide-toggle>
  <ul class="palette" *ngIf="enabled">
    <md-card *ngFor="let basicColorName of getBasicColorNames()"
             class="palette-box"
             [class.md-elevation-z24]="basicColorName !== selectedBasicColorName"
             [class.clickable]="basicColorName !== selectedBasicColorName"
             [style.background-color]="basicColorName"
             [class.palette-box-selected]="basicColorName === selectedBasicColorName"
             (click)="selectedBasicColorName = basicColorName">
    </md-card>
  </ul>
</div>
