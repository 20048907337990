<div class="flex-horizontal-container view-container">
  <app-view [position]="position" [opacity]="opacity" [antiReflection]="antiReflection"
            [outlineWhenFocused]="outlineFocusedView"
            [showActions]="showActions"
            [showHeader]="showHeader"
            [class.clickable]="activeViewIndex !== 0" (click)="activateView(0)"
            [focused]="activeViewIndex === 0"></app-view>
  <app-view *ngIf="viewsLayout === ViewsLayout.TWO_HORIZONTAL"
            [position]="position" [opacity]="opacity" [antiReflection]="antiReflection"
            [outlineWhenFocused]="outlineFocusedView"
            [showActions]="showActions"
            [showHeader]="showHeader"
            [class.clickable]="activeViewIndex !== 1" (click)="activateView(1)"
            [focused]="activeViewIndex === 1"></app-view>
</div>
