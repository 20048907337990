<div class="classic-landscape flex-horizontal-container">
  <div class="flex-1">
    <div class="flex-vertical-container">
      <md-card>
        <img src="assets/favicon.ico" style="height: 60px;"/>
        <button type="button" md-fab color="accent" style="float: right">
          <md-icon fontSet="fa" fontIcon="fa-map-marker" class="fa-2x"></md-icon>
        </button>
      </md-card>
      <app-lense-treatment-selector></app-lense-treatment-selector>
      <md-card>
        <app-interpupillary-distance></app-interpupillary-distance>
      </md-card>
      <md-card class="text-center flex-1">
        <img src="assets/position-instructions.png"/>
      </md-card>
      <md-card>
        <app-reset></app-reset>
      </md-card>
    </div>
  </div>
  <div class="flex-1">
    <div class="flex-vertical-container">
      <md-card>
        <app-views-layout></app-views-layout>
        <app-sharing></app-sharing>
      </md-card>
      <app-views></app-views>
      <md-card>
        <app-media-source-selector></app-media-source-selector>
      </md-card>
    </div>
  </div>
  <div [style.width]="viewsLayout === ViewsLayout.TWO_HORIZONTAL? '480px':'560px'">
    <div class="flex-vertical-container">
      <md-card>
        <app-color-range-picker-palette></app-color-range-picker-palette>
      </md-card>
      <app-product-selector-vertical-list class="flex-1"></app-product-selector-vertical-list>
    </div>
  </div>
</div>
