<div class="flex-vertical-container">
  <md-card class="flex-1">
    <div class="flex-vertical-container">
      <div class="text-center flex-1">
        <img *ngIf="lastSelectedProduct" [src]="productService.getProductThumbnailSrc(lastSelectedProduct)"/>
      </div>
      <p class="text-center">{{lastSelectedProduct?.brand?.name}}</p>
      <p class="text-center">{{lastSelectedProduct?.model}}</p>
    </div>
  </md-card>
</div>
