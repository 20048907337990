<div class="flex-vertical-container">
  <md-card [class.focused]="outlineWhenFocused && focused" [class.md-elevation-z24]="outlineWhenFocused && !focused" class="view-card flex-1">
    <md-card-header style="height:75px; width: 377px;" *ngIf="showHeader">
      <img md-card-avatar class="product-avatar-img" *ngIf="selectedProduct"
           [src]="productService.getProductThumbnailSrc(selectedProduct)">
      <md-card-title>{{selectedProduct?.brand?.name}}</md-card-title>
      <md-card-subtitle>{{selectedProduct?.model}}</md-card-subtitle>
    </md-card-header>
    <md-card-actions class="text-center" *ngIf="showActions">
      <button md-raised-button color="primary" (click)="enableFullScreen()">
        <md-icon class="md-24">fullscreen</md-icon>
      </button>
      <button md-raised-button color="primary" (click)="showThreeSixty()" *ngIf="selectedProduct">
        <img src="assets/360.png"/>
      </button>
    </md-card-actions>
    <md-card-content class="text-center">
      <canvas #canvas3D
              class="movable"
              [style.transform]="transform"
              [style.width]="canvasDimensions.width + 'px'"
              [style.height]="canvasDimensions.height + 'px'"
              (mousemove)="onCanvasMouseMove($event)" (contextmenu)="onCanvasRightClick($event)"></canvas>
    </md-card-content>
  </md-card>
</div>
