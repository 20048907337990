<div class="text-center instructions">
  <!--Before record start-->
  <h3 *ngIf="recordStatus === RecordStatus.NONE">
    You are about to record a {{recordDuration}} seconds video.<br>
    Please remove your glasses before you begin.
  </h3>
  <button md-raised-button (click)="startRecording()" color="primary" *ngIf="recordStatus === RecordStatus.NONE">
    <md-icon class="md-24">videocam</md-icon>
    Start the video recording
  </button>
  <!--During record start countdown-->
  <h3 *ngIf="recordStatus === RecordStatus.STARTING">Video recording will start in</h3>
  <!--During record end countdown-->
  <h3 *ngIf="recordStatus === RecordStatus.RUNNING">Video recording will end in</h3>
  <h1 *ngIf="recordStatus === RecordStatus.STARTING || recordStatus === RecordStatus.RUNNING">{{countdown}}</h1>
</div>
<div class="video-preview-container text-center">
  <canvas #canvasPreview [width]="canvasDimensions.width" [height]="canvasDimensions.height"></canvas>
</div>
<div class="text-center actions">
  <button type="button" md-fab (click)="cancel()" color="warn" *ngIf="recordStatus === RecordStatus.NONE">
    <md-icon class="md-24">clear</md-icon>
  </button>
</div>
