<div *ngIf="!droppedPictureDataUrl"
     ng2FileDrop [uploader]="pictureUploader"
     [class.drop-box-overed]="fileOvered" (fileOver)="onFileOver($event)" class="drop-zone text-center">
  <input id="picture-file-input" type="file" class="input-file" ng2FileSelect [uploader]="pictureUploader"/>
  <label for="picture-file-input" md-raised-button class="md-primary">
    <md-icon class="md-24">add_a_photo</md-icon>
    Choose or drop a picture of your face
  </label>
</div>

<div *ngIf="droppedPictureDataUrl" class="text-center">
  <button md-fab (click)="zoomIn()" color="primary">
    <md-icon class="md-24">zoom_in</md-icon>
  </button>
  <button md-fab (click)="zoomOut()" color="primary">
    <md-icon class="md-24">zoom_out</md-icon>
  </button>
</div>

<div *ngIf="droppedPictureDataUrl" class="dropped-picture-viewer">
  <img [src]="droppedPictureDataUrl" (load)="onDroppedImageLoaded($event)"/>
</div>

<div class="text-center">
  <button *ngIf="droppedPictureDataUrl" md-fab (click)="validate()" color="primary">
    <md-icon class="md-24">check</md-icon>
  </button>

  <button type="button" md-fab (click)="cancel()" color="warn">
    <md-icon class="md-24">clear</md-icon>
  </button>
</div>
