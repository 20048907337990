<div *ngIf="mode === Mode.PREVIEW">
  <img *ngFor="let snapshotDataUrl of snapshotDataUrls" [src]="snapshotDataUrl" style="margin: 50px"/>
</div>
<div *ngIf="mode === Mode.QRCODE">
  <img *ngFor="let qrCodeDataUrl of qrCodeDataUrls" [src]="qrCodeDataUrl" style="margin: 50px"/>
</div>
<div class="text-center">
  <button type="button" md-fab color="primary" (click)="showQrCodes()" *ngIf="mode === Mode.PREVIEW">
    <md-icon fontSet="fa" fontIcon="fa-qrcode" class="fa-2x"></md-icon>
  </button>
  <button type="button" md-fab color="primary" (click)="showViewers()" *ngIf="mode === Mode.PREVIEW">
    <md-icon class="md-24">email</md-icon>
  </button>
  <button type="button" md-fab (click)="cancel()" color="warn" *ngIf="true">
    <md-icon class="md-24">clear</md-icon>
  </button>
</div>
