<div class="remote-control-layout flex-vertical-container">
  <div class="flex-vertical-container channel-field-container" *ngIf="status === Status.ENTER_CHANNEL">
    <md-form-field class="full-width form-field-center">
      <input mdInput type="text" [(ngModel)]="channelName"
             autofocus
             style="text-transform: uppercase" placeholder="Code"/>
    </md-form-field>
  </div>

  <md-card class="flex-3" *ngIf="status === Status.OK">
    <app-product-selector-horizontal-swiper></app-product-selector-horizontal-swiper>
  </md-card>
  <md-card class="flex-1" *ngIf="status === Status.OK">
    <div class="flex-vertical-container flex-align-center flex-justify-center">
      <button md-fab color="accent" (click)="takeSnapshot()">
        <md-icon class="md-24">photo_camera</md-icon>
      </button>
    </div>
  </md-card>

  <div class="flex-1" *ngIf="status === Status.SNAPSHOTS_TOOK">
    <div class="flex-vertical-container flex-align-center flex-justify-center">
      <md-spinner></md-spinner>
    </div>
  </div>


  <md-card class="flex-1" *ngIf="status === Status.SNAPSHOTS_RECEIVED">
    <div class="flex-horizontal-container flex-align-center">
      <div *ngFor="let url of snapshotDataUrls">
        <img [src]="url" style="max-width: 100%; max-height: 100%"/>
      </div>
    </div>
  </md-card>

  <md-card class="flex-1" style="margin-top: 10px" *ngIf="status === Status.SNAPSHOTS_RECEIVED">
    <div class="flex-vertical-container flex-align-center flex-justify-center">
      <button md-fab color="primary" (click)="aknowledgeSnapshots()">
        <md-icon class="md-24">check</md-icon>
      </button>
    </div>
  </md-card>

</div>
