<div *ngIf="product" class="text-center threesixty-header">
  <h3 md-line>{{product.brand.name}}</h3>
  <p md-line>
    <span>{{product.model}}</span>
  </p>
</div>
<div #threeSixtyContainer class="threesixty">
  <div class="spinner">
    <span>0%</span>
  </div>
  <ol class="threesixty_images"></ol>
</div>
<div class="text-center">
  <button type="button" md-fab (click)="previous()" color="primary" *ngIf="true">
    <md-icon class="md-24">skip_previous</md-icon>
  </button>
  <button type="button" md-fab (click)="play()" *ngIf="true">
    <md-icon class="md-24">play_arrow</md-icon>
  </button>
  <button type="button" md-fab (click)="pause()" color="warn" *ngIf="true">
    <md-icon class="md-24">pause</md-icon>
  </button>
  <button type="button" md-fab (click)="next()" color="primary" *ngIf="true">
    <md-icon class="md-24">skip_next</md-icon>
  </button>
  <button type="button" md-fab (click)="cancel()" color="warn" *ngIf="true">
    <md-icon class="md-24">clear</md-icon>
  </button>
</div>

