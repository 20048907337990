<div class="flex-horizontal-container source-selector-container">
  <button type="button" md-raised-button color="primary" (click)="mediaSourceType = MediaSourceType.CAMERA"
          *ngIf="mediaSourceType !== MediaSourceType.CAMERA">
    <md-icon class="md-24">videocam</md-icon> I want a live view
  </button>
  <button type="button" md-raised-button color="primary" (click)="mediaSourceType = MediaSourceType.VIDEO"
          *ngIf="mediaSourceType !== MediaSourceType.VIDEO">
    <md-icon class="md-24">camera_roll</md-icon> I can't see without my glasses
  </button>
  <button type="button" md-raised-button color="primary" (click)="mediaSourceType = MediaSourceType.PICTURE"
          *ngIf="mediaSourceType !== MediaSourceType.PICTURE">
    <md-icon class="md-24">photo_library</md-icon> I don't have a camera
  </button>
</div>
